import React from "react";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export const Breadcrumb = ({ active, children, onClick, ...props }) => {
  if (active || !onClick) {
    return (
      <Typography color="text.primary" {...props}>
        {children}
      </Typography>
    );
  }

  return (
    <Link variant="pointer" underline="none" onClick={onClick} {...props}>
      {children}
    </Link>
  );
};
