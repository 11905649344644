import React from "react";

import GitHubConnectButton from "../buttons/GitHub";
import GoogleConnectButton from "../buttons/Google";
import TwitterConnectButton from "../buttons/Twitter";
import LinkedInConnectButton from "../buttons/LinkedIn";
import { SocialAnchor } from "./SocialAnchor";

export default function SocialButtons({ loginUrls }) {
  const mappings = {
    github: <GitHubConnectButton />,
    google: <GoogleConnectButton />,
    linkedin: <LinkedInConnectButton />,
    twitter: <TwitterConnectButton />,
  };

  const buttons = [];

  Object.keys(loginUrls).forEach((network) => {
    const button = mappings[network];
    buttons.push(
      <SocialAnchor key={network} href={loginUrls[network]}>
        {button}
      </SocialAnchor>
    );
  });

  return buttons;
}
