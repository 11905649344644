import React from "react";

import ProfileContent from "./ProfileContent";
import InputField from "./components/InputField";

export default function ComposePage({ formik }) {
  return (
    <ProfileContent>
      <InputField
        sx={{
          marginTop: ".5rem",
        }}
        label="Subject"
        name="subject"
        formik={formik}
      />
      <InputField
        sx={{
          marginTop: ".5rem",
          flexGrow: 1,
        }}
        InputProps={{
          sx: {
            height: "100%",
            alignItems: "normal",
          },
        }}
        multiline
        minRows="8"
        maxRows="20"
        label="Message"
        name="body"
        formik={formik}
      />
    </ProfileContent>
  );
}
