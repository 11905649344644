import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ModalNav } from "./ModalNav";

function Title(title, fontWeight, varient) {
  return (
    <Box
      sx={{
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Typography
        sx={{
          fontWeight: fontWeight,
        }}
        variant={varient}
      >
        {title}
      </Typography>
    </Box>
  );
}

export const ModalTitle = ({ page, pages, setPageName }) => {
  if (page && page.title) {
    return Title(page.title.text, page.title.fontWeight, page.title.variant);
  }

  if (page && page.showBreadcrumbs === false) {
    return <></>;
  }

  return (
    // Breadcrumbs
    <Box>
      {/* XXX: extract out */}
      <ModalNav
        pages={pages}
        stepName={page && page.name}
        setPageName={setPageName}
      />
    </Box>
  );
};
