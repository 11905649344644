import React from "react";

import CircularProgress from "@mui/material/CircularProgress";

import CenteredBox from "./CenteredBox";

export const Progress = () => {
  /*
    useEffect(() => {
      const timer = setTimeout(() => setTransitioning(false), 450);
      return () => clearTimeout(timer);
    }, []);
    */

  return (
    <CenteredBox>
      <CircularProgress />
    </CenteredBox>
  );
};
