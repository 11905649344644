import * as React from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { amber, grey } from '@mui/material/colors';

import "@fontsource/montserrat/700.css";
import HeroPage from "./Hero";
import ColorModeContext from './ColorModeContext'

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    text: {
      highlight: "#00cdac",
    },
    ...(mode === 'light'
    ? {
        bgcolor: {
          secondary: amber[50],
        }
      }
    : {
        bgcolor: {
          secondary: grey[500],
        }
      }
    )}
});

function App() {
  return (
    <HeroPage />
  );
}

export default function ToggleColorMode() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [mode, setMode] = React.useState(prefersDarkMode ? 'dark' : 'light');

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
  [setMode]);

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
