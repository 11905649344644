import React from "react";

import { ModalErrors } from "./modal/ModalErrors";

export default function DialogErrors() {
  const searchParams = new URLSearchParams(window.location.search);
  const error = searchParams.get("error_description");

  if (error === null) {
    return <></>;
  }

  const responseError = {
    errors: [
      Object.create({
        detail: error,
      }),
    ],
  };

  return <ModalErrors responseError={responseError} />;
}
