import React from "react";

import Box from "@mui/material/Box";

const CenteredBox = ({ overrides, children }) => {
  const defaults = {
    xs: "center",
    sm: "center",
  };

  const flexProps = { ...defaults, ...overrides };

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        alignItems: flexProps,
        alignContent: flexProps,
        justifyContent: flexProps,
      }}
    >
      <Box
        sx={{
          alignContent: flexProps,
          flexGrow: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default CenteredBox;
