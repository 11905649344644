import React from "react";
import TextField from '@mui/material/TextField';


export default function InputField({ label, name, formik, ...props }) {
  return (
    <>
     <TextField
        fullWidth
        id={name}
        name={name}
        label={label}
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        {...props}
      />
    </>
  );
}

/*
    <Field
      component={TextField}
      fullWidth
      label={label}
      name={name}
      {...props}
    />
  );
*/
