import React, { useContext } from "react";

import Box from "@mui/material/Box";

import SessionContext from "./SessionContext";

export default function ConfirmPage() {
  const session = useContext(SessionContext);
  const profile = (session.user && session.user.primary_profile) || {};

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: 1,
      }}
    >
      Thanks {profile.name}. Your message has been sent. Have a great day!
    </Box>
  );
}
