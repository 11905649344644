import React, { useContext } from "react";

import CenteredBox from "./components/CenteredBox";
import Profile from "./components/Profile";

import SessionContext from "./SessionContext";

const ConnectConfirmPage = () => {
  const session = useContext(SessionContext);
  const profile = (session.user && session.user.primary_profile) || {};

  return (
    <CenteredBox>
      <Profile profile={profile} width="8rem" height="8rem" />
    </CenteredBox>
  );
};

export default ConnectConfirmPage;
