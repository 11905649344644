import React from "react";

import Box from "@mui/material/Box";

import SessionContext from "./SessionContext";
import Profile from "./components/Profile";

export default function ProfileContent({ children }) {
  const session = React.useContext(SessionContext);
  const profile = (session.user && session.user.primary_profile) || {};

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          alignSelf: "flex-start",
          textAlign: "center",
          lineHeight: ".5rem",
          fontSize: "1rem",
          display: {
            xs: "none",
            sm: "block",
          },
          mt: "2rem",
          mx: "1rem",
        }}
      >
        <Profile profile={profile} width="7rem" height="7rem" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
