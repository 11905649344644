import React from "react";

import { Progress } from "../components/Progress";

import { ModalErrors } from "./ModalErrors";

const ModalContent = ({ transitioning, page, responseError }) => {
  if (transitioning) {
    return <Progress />;
  }

  return (
    <>
      <ModalErrors responseError={responseError} />
      {page}
    </>
  );
};

export default ModalContent;
