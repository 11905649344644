import React from "react";

import Alert from "@mui/material/Alert";

import { titleCase } from "../utils";

export const ModalErrors = ({ responseError }) => {
  if (!responseError) {
    return;
  }

  const { errors, detail } = responseError;

  let message;

  if (errors) {
    if (errors && errors.length === 1) {
      message = <span>{titleCase(errors[0].detail)}</span>;
    } else {
      message = (
        <ul>
          {errors.map((el, i) => (
            <li key={i}>{titleCase(el.detail)}</li>
          ))}
        </ul>
      );
    }
  }

  return (
    <Alert severity="error">
      {detail}
      {message}
    </Alert>
  );
};
