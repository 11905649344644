import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from "@mui/material/TableCell";
import TableRow from '@mui/material/TableRow';


import { ContactModal } from "./contact/ContactModal";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ToggleColorModeIcon from './ToggleColorModeIcon';

import SocialLinks from './SocialLinks';

const HeroText = React.forwardRef(({ onEntered }, ref) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const textAlign = smallScreen ? "center" : "left";

  return (
    <Slide
      direction="right"
      in={true}
      timeout={{
        appear: 1000,
        enter: 2000,
      }}
      onEntered={onEntered}
    >
      <Box
        ref={ref}
        sx={{
          fontSize: "3.5rem",
          fontWeight: 700,
          lineHeight: "1.2",
          textAlign: textAlign,
          fontFamily: "Montserrat, sans-serif"
        }}
      >
        <Box
          sx={{
            fontFamily: "Montserrat, sans-serif"
          }}
        >
          Hi, my name is{" "}
          <Box component="span" sx={{ color: "text.highlight" }}>
            Derrick.
          </Box>
        </Box>
        <Box>Welcome to my home on the web.</Box>
      </Box>
    </Slide>
  );
});

function HeroContact({setAdmin}) {
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false) };
  const handleShow = () => { setShow(true) };
  const theme = useTheme();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('open')) {
      setShow(searchParams.get('open') === "true")
    }
  }, [setShow])

  return (
    <Box
      sx={{
        marginRight: "auto",
        marginLeft: "auto",
        textAlign: "center",
        padding: "2rem",
      }}
    >
      <Button variant="contained" onClick={handleShow} onKeyPress={handleShow}>
        Contact me
      </Button>
      <ContactModal
        apiEndpoint={process.env.REACT_APP_API_ENDPOINT}
        setAdmin={setAdmin}
        dialogOpen={show}
        closeDialog={handleClose}
        theme={theme}
      />
    </Box>
  );
}

function HeroContent({ children }) {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "2rem",
        flexDirection: "column",
      }}
    >
      {children}
    </Box>
  );
}

function HeroAdmin() {
  const [certInfo, setCertInfo] = React.useState({});
  
  useEffect(() => {
    async function makeRequest() {
      if (!initialized) {
        const response = await fetch("https://localhost:8000/api/cert/", {
          method: "GET",
          credentials: "include",
        });

        const json = await response.json();
        setCertInfo(json);
      }
    }

    let initialized = false;
    makeRequest();

    return () => {
      initialized = true;
    };

  }, []);

  return (
    <Box>
      <Table size="small">
        <TableBody>
          {Object.entries(certInfo).map(([key, value]) => (
            <TableRow
              key={key}
              sx={{  
                border: 0,
                borderBottom: "none"
              }}
            >
              <TableCell>
                {key}
              </TableCell>
              <TableCell>
                {value}
              </TableCell>
            </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default function HeroPage() {
  const [showFade, setShowFade] = React.useState(false);
  const [admin, setAdmin] = React.useState(false);

  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        display: "flex",
        minHeight: "100dvh",
        bgcolor: 'background.default',
        color: 'text.primary',
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            justifyContent: "flex-start",
          }}
        >
          {admin && <HeroAdmin />}
        </Box>
        <Box
          sx={{
            justifyContent: "flex-end",
          }}
        >
          <ToggleColorModeIcon />
        </Box>
      </Box>
      <HeroContent>
        <HeroText onEntered={() => setShowFade(true)} />
        <SocialLinks showFade={showFade} />
        <HeroContact setAdmin={setAdmin} />
      </HeroContent>
        <Box>
        </Box>
    </Box>
  );
}
