import React from "react";
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

import SocialIcon from './SocialIcon';


const SocialLinks = React.forwardRef(({ showFade }, ref) => {
  return (
    <Fade
      in={showFade}
      timeout={{
        appear: 2000,
        enter: 2000,
      }}
    >
      <Box
        ref={ref}
        sx={{
          marginTop: "2rem",
          textAlign: "center",
        }}
      >
        <SocialIcon name="github" link="https://github.com/dpetzold" />
        <SocialIcon name="medium" link="https://medium.com/@dpetzold" />
        <SocialIcon name="twitter" link="https://twitter.com/derrickpetzold" />
        <SocialIcon
          name="linkedin"
          link="https://linkedin.com/derrickpetzold"
        />
      </Box>
    </Fade>
  );
});

export default SocialLinks
