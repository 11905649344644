import React, { useContext } from "react";

import Box from "@mui/material/Box";

import InputField from "./components/InputField";
import SocialButtons from "./components/SocialButtons";
import CenteredBox from "./components/CenteredBox";

import DialogErrors from "./DialogErrors";
import SessionContext from "./SessionContext";

export default function ConnectPage() {
  const session = useContext(SessionContext);
  const loginUrls = session.login_urls || {};

  return (
    <CenteredBox>
      <DialogErrors />
      <SocialButtons loginUrls={loginUrls} />
      {process.env.REACT_APP_EMAIL_REGISTRATION === "true" && (
        <>
          <Box
            sx={{
              marginTop: "16px",
              flexGrow: 1,
              textAlign: "center",
            }}
          >
            <u>Or</u> enter your email address <em>(verification required)</em>:
          </Box>

          <InputField label="Email Address" type="email" name="email" />
        </>
      )}
    </CenteredBox>
  );
}
