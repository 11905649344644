import React, { useContext } from "react";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import MessageContext from "./MessageContext";
import ProfileContent from "./ProfileContent";

export default function PreviewPage() {
  const message = useContext(MessageContext);
  const theme = useTheme();

  return (
    <ProfileContent>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          flexGrow: 1,
          p: 1,
          bgcolor: theme.palette.bgcolor.secondary,
        }}
      >
        <p>Subject: {message.subject}</p>
        <p>{message.body}</p>
      </Box>
    </ProfileContent>
  );
}
