import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import * as Yup from "yup";

import { ContactForm } from "./ContactForm";

import { Request } from "./Request";

import ComposePage from "./ComposePage";
import ConfirmPage from "./ConfirmPage";
import ConnectConfirmPage from "./ConnectConfirmPage";
import ConnectPage from "./ConnectPage";
import PreviewPage from "./PreviewPage";
import ProfilesPage from "./ProfilesPage";

/*
 * - Use dict for stepNumber
 * - Use reducer
 *
 */

const pageData = {
  connect: {
    next: "connect-confirm",
    showSubmit: false,
    title: {
      text: "Let's connect",
      fontWeight: "700",
      variant: "h4",
    },
    component: ConnectPage,
    props: ["loginUrls"],
  },
  "connect-confirm": {
    previous: "connect",
    next: "compose",
    title: {
      text: "You are connecting as",
      fontWeight: "100",
      variant: "h5",
    },
    submitText: "Continue",
    showBackButton: true,
    showSubmit: true,
    excludeFromBreadcrumbs: true,
    component: ConnectConfirmPage,
    props: ["profile"],
  },
  profiles: {
    previous: "connect",
    next: "compose",
    title: {
      text: "Manage your Profile",
      fontWeight: "100",
      variant: "h5",
    },
    submitText: "Next",
    showBackButton: false,
    showSubmit: true,
    excludeFromBreadcrumbs: true,
    component: ProfilesPage,
    props: ["profiles"],
  },
  compose: {
    previous: "profiles",
    next: "preview",
    submitText: "Preview",
    showSubmit: true,
    showAccountMenu: true,
    showBackButton: true,
    validationSchema: Yup.object({
      subject: Yup.string()
        .max(100, "Must be 100 characters or less")
        .required("Required"),
      body: Yup.string()
        .min(5, "Must be 5 characters or more")
        .max(10000, "Must be 10000 characters or less")
        .required("Required"),
    }),
    component: ComposePage,
    props: ["profile"],
  },
  preview: {
    previous: "compose",
    next: "confirm",
    backText: "Edit",
    showBreadcrumbs: false,
    showBackButton: true,
    submitText: "Send",
    showSubmit: true,
    component: PreviewPage,
    props: ["profile"],
  },
  confirm: {
    previous: "preview",
    showBreadcrumbs: false,
    submitText: "Ok",
    showSubmit: true,
    component: ConfirmPage,
    props: ["profile"],
  },
};

export function ContactModal({
  apiEndpoint,
  setAdmin,
  dialogOpen,
  closeDialog,
  theme,
}) {
  const [transitioning, setTransitioning] = useState(false);
  const [sessionData, setSessionData] = useState({});
  const [responseError, setResponseError] = useState(null);
  const [pageName, setPageName] = useState("connect");

  const request = Request({
    apiEndpoint,
    setSessionData,
    setResponseError,
    setAdmin,
  });

  useEffect(() => {
    async function initSession() {
      if (!initialized) {
        const session = await request.getSession();
        if (session) {
          if (session.profiles) {
            setPageName("connect-confirm");
          }
        } else {
          setPageName("connect");
        }
      }
    }

    let initialized = false;
    initSession();

    return () => {
      initialized = true;
    };
  }, []);

  const Reset = (formik) => {
    // try https://formik.org/docs/api/withFormik
    closeDialog();
    if (formik) {
      formik.resetForm();
    }
  };

  const logout = () => {
    request.logout(() => {
      setPageName("connect");
      Reset();
    });
  };

  return (
    <ContactForm
      request={request}
      responseError={responseError}
      dialogOpen={dialogOpen}
      closeDialog={closeDialog}
      transitioning={transitioning}
      setTransitioning={setTransitioning}
      Reset={Reset}
      logout={logout}
      pageName={pageName}
      setPageName={setPageName}
      pageData={pageData}
      sessionData={sessionData}
      parentTheme={theme}
      setAdmin={setAdmin}
    />
  );
}

ContactModal.propTypes = {
  apiEndpoint: PropTypes.string,
  dialogOpen: PropTypes.bool,
  closeDialog: PropTypes.func,
};
