import React, { useState } from "react";

import { Form, Formik } from "formik";

import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Input from "@mui/material/Input";
import Stack from "@mui/material/Stack";
import { ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import ModalHeader from "./modal/ModalHeader";
import ModalContent from "./modal/ModalContent";
import MessageContext from "./MessageContext";
import SessionContext from "./SessionContext";

import theme from "./theme";

/*
 *
 * Todo
 * ----
 *
 * * combine state
 * * use reducer
 *
 */

export function ContactForm({
  request,
  responseError,
  dialogOpen,
  closeDialog,
  transitioning,
  setTransitioning,
  Reset,
  logout,
  profileDialog,
  pageData,
  pageName,
  setPageName,
  sessionData,
  parentTheme,
}) {
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("md"));
  const [snapshot, setSnapshot] = useState({});
  const DEBUG = false;

  const page = pageData[pageName];

  const showBackButton = () => {
    if (!transitioning && page.showBackButton) {
      return page.showBackButton;
    }

    return false;
  };

  const nextStep = () => {
    if (page.next) {
      setPageName(page.next);
    }
  };

  const previousStep = (formik) => {
    formik.validateForm().then(() => {
      setSnapshot(formik.values);
      setPageName(page.previous);
    });
  };

  const handleSubmit = async (formik) => {
    const { values, setErrors } = formik;

    if (pageName === "connect-confirm") {
      setPageName("compose");
      return;
    }

    if (pageName === "confirm") {
      setPageName("compose");
      Reset(formik);
      return;
    }

    if (pageName === "compose") {
      setSnapshot(values);
    }

    if (pageName === "preview") {
      setTransitioning(true);

      const success = await request.sendMessage(values, setErrors);

      setTransitioning(false);

      if (success) {
        return nextStep();
      }

      return;
    }

    return nextStep();
  };

  const SubmitText = () => {
    return page.submitText ? page.submitText : "Next";
  };

  const BackText = () => {
    return page.backText ? page.backText : "Back";
  };

  const showSubmit = () => {
    return transitioning === false && page.showSubmit;
  };

  const submitDisabled = (formik) => {
    if (page.name !== "compose") {
      return false;
    }

    if (DEBUG) {
      console.log(`isValid=${formik.isValid}`);
      console.log(`dirty=${formik.dirty}`);
      console.log(`submitting=${formik.isSubmitting}`);
      console.log(formik.errors);
    }

    return !(formik.isValid && formik.dirty) || formik.isSubmitting;
  };

  return (
    <ThemeProvider theme={theme(parentTheme)}>
      <Formik
        initialValues={snapshot}
        validationSchema={
          page && page.validationSchema ? page.validationSchema : null
        }
        validateOnMount="true"
      >
        {(formik) => (
          <Form>
            <Dialog
              fullWidth
              fullScreen={fullScreen}
              open={dialogOpen}
              onClose={closeDialog}
              Paperpage={{
                sx: {
                  justifyContent: "space-between",
                },
              }}
            >
              <DialogTitle>
                <ModalHeader
                  page={page}
                  transitioning={transitioning}
                  closeDialog={closeDialog}
                  profileDialog={profileDialog}
                  logout={logout}
                  pages={pageData}
                  setPageName={setPageName}
                />
              </DialogTitle>
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <MessageContext.Provider value={snapshot}>
                  <SessionContext.Provider value={sessionData}>
                    <ModalContent
                      transitioning={transitioning}
                      page={React.createElement(page.component, {
                        request: request,
                        formik: formik,
                      })}
                      responseError={responseError}
                    />
                  </SessionContext.Provider>
                </MessageContext.Provider>
              </DialogContent>
              <DialogActions>
                <Stack
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      alignSelf: "flex-end",
                    }}
                  >
                    {page.name === "connect" && (
                      <Button onClick={closeDialog} color="primary">
                        Cancel
                      </Button>
                    )}

                    {showBackButton() && (
                      <Button
                        onClick={() => previousStep(formik)}
                        type="button"
                      >
                        <BackText />
                      </Button>
                    )}

                    {showSubmit() && (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={submitDisabled(formik)}
                        type="submit"
                        onClick={() => handleSubmit(formik)}
                      >
                        <SubmitText />
                      </Button>
                    )}
                    <Input sx={{ display: "none" }} type="submit" />
                  </Box>
                </Stack>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    </ThemeProvider>
  );
}

ContactForm.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
  dialogOpen: PropTypes.bool,
  closeDialog: PropTypes.func,
  transitioning: PropTypes.bool,
  setTransitioning: PropTypes.func,
  sessionEnpoint: PropTypes.string,
};
