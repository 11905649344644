import React from "react";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Breadcrumb } from "../components/Breadcrumb";
import { titleCase } from "../utils";

export const ModalNav = ({ pages, pageName, setPageName }) => {
  const crumbs = [];
  let has_link = true;

  for (const [key, page] of Object.entries(pages)) {
    const page_name = key;
    const props = {
      key: page_name,
    };

    if (has_link) {
      props["onClick"] = () => setPageName(page_name);
    }

    if (page_name === pageName) {
      props["active"] = true;
      has_link = false;
    } else {
      props["color"] = "inherit";
    }

    if (!page.excludeFromBreadcrumbs) {
      crumbs.push(
        <Breadcrumb {...props}>
          {titleCase(page.breadcrumb ? page.breadcrumb : page_name)}
        </Breadcrumb>,
      );
    }
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" component="span" separator=">">
      {crumbs}
    </Breadcrumbs>
  );
};
