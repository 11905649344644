import React from "react";
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faTwitter, faMedium, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Icons = {
  github: faGithub,
  twitter: faTwitter,
  medium: faMedium,
  linkedin: faLinkedin,
};

export default function SocialIcon({ name, link }) {
  return (
    <Box
      sx={{
        marginRight: "2rem",
        display: "inline",
        color: "#a9a9a9",
        "& a:-webkit-any-link": {
          color: "#a9a9a9",
        },
      }}
    >
      <a href={link} alt={name}>
        <FontAwesomeIcon icon={Icons[name]} size="2xl" />
      </a>
    </Box>
  );
}
