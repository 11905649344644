import React, { useContext } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { SocialIcon } from "react-social-icons";

import SessionContext from "./SessionContext";
import Profile from "./components/Profile";
import { SocialAnchor } from "./components/SocialAnchor";

const ProfileWithButtons = ({ profile, request }) => {
  const session = useContext(SessionContext);

  const style = () => {
    const base = {};

    if (profile.is_primary) {
      base["&.MuiButtonBase-root:hover"] = {
        bgcolor: "transparent",
        borderColor: "rgba(85, 108, 214, 0.5)",
      };
    }

    return base;
  };

  return (
    <Profile profile={profile} width="8rem" height="8rem">
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Button
          size="small"
          variant={profile.is_primary ? "outlined" : "contained"}
          disableElevation={profile.is_primary}
          disableRipple={profile.is_primary}
          disableFocusRipple={profile.is_primary}
          onClick={() => {
            request.make_primary(profile.network_name);
          }}
          sx={style(profile.is_primary)}
        >
          {profile.is_primary ? "Active" : "Make Active"}
        </Button>

        <Button
          sx={{
            flexGrow: 1,
          }}
          variant="outlined"
          size="small"
          disabled={profile.is_primary && session.profiles.length > 1}
          onClick={() => {
            request.delete_profile(profile.network_name);
          }}
        >
          Disconnect
        </Button>
      </Box>
    </Profile>
  );
};

export default function ProfilesPage({ request }) {
  const session = useContext(SessionContext);

  const profiles = () => {
    const _profiles = [];
    const component = (profile) => {
      return <ProfileWithButtons profile={profile} request={request} />;
    };

    const _filter_profiles = (profiles, callback) => {
      session.profiles
        .filter((profile) => callback(profile))
        .forEach((profile) => {
          profiles.push(component(profile));
        });
    };

    _filter_profiles(_profiles, (profile) => profile.is_primary);
    _filter_profiles(_profiles, (profile) => !profile.is_primary);

    return _profiles;
  };

  const hasProfile = (network_name) => {
    return session.profiles.find(
      (profile) => profile.network_name === network_name,
    );
  };

  const SocialIconFull = (props) => {
    return (
      <SocialIcon
        style={{
          width: "100%",
          height: "100%",
        }}
        {...props}
      />
    );
  };

  const networkProfiles = {
    google: {
      name: "Google",
      username: <>&nbsp;</>,
      icon: <SocialIconFull network="google" />,
    },
    twitter: {
      name: "X",
      username: <>&nbsp;</>,
      icon: <SocialIconFull network="x" />,
    },
    linkedin: {
      name: "LinkedIn",
      username: <>&nbsp;</>,
      icon: <SocialIconFull network="linkedin" />,
    },
    github: {
      name: "Github",
      username: <>&nbsp;</>,
      icon: <SocialIconFull network="github" />,
    },
  };

  const logins = Object.keys(session.login_urls).reduce((results, network) => {
    if (!hasProfile(network)) {
      results.push(
        <Profile profile={networkProfiles[network]} width="8rem" height="8rem">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <SocialAnchor key={network} href={session.login_urls[network]}>
              <Button size="small" variant="contained">
                Connect
              </Button>
            </SocialAnchor>
          </Box>
        </Profile>,
      );
    }
    return results;
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        justifyContent: "space-between",
        alignItems: {
          xs: "center",
          sm: "flex-start",
        },
        flexWrap: "wrap",
        gap: "1rem",
      }}
    >
      {profiles()}
      {logins}
    </Box>
  );
}
