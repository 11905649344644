import React from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";

export default function Profile({ profile, width, height, children }) {
  const { name, avatar_url, network_name, icon, username, email } = profile;

  const theme = useTheme();

  const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body1,
    textAlign: "center",
  }));

  function Username() {
    const prefix = network_name === "twitter" ? "@" : "";
    return (
      <>
        {prefix}
        {username ? username : email}
      </>
    );
  }

  const avatar_style = () => {
    return {
      width: width,
      height: height,
      my: ".5rem",
    };
  };

  function UserAvatar({ name, avatar_url }) {
    return <Avatar alt={name} src={avatar_url} sx={avatar_style()} />;
  }

  function NetworkAvatar({ name, Icon }) {
    return (
      <Avatar alt={name} sx={avatar_style()}>
        {Icon}
      </Avatar>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {avatar_url && <UserAvatar name={name} avatar_url={avatar_url} />}

      {icon && <NetworkAvatar name={name} Icon={icon} />}

      <Item
        sx={{
          flexWrap: "nowrap",
          alignContent: "center",
          maxWidth: { width },
        }}
      >
        <Typography
          textAlign="center"
          lineHeight="1.1rem"
          variant="subtitle1"
          flexWrap="nowrap"
        >
          {name}
        </Typography>
      </Item>
      <Item
        sx={{
          alignSelf: "center",
          color: theme.palette.text.secondary,
        }}
      >
        <Typography lineHeight=".5rem" fontSize="1rem" variant="caption">
          <Username />
        </Typography>
      </Item>
      {children}
    </Box>
  );
}
