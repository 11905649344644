import React, { useState } from "react";

export function Request({
  apiEndpoint,
  setSessionData,
  setResponseError,
  setAdmin,
}) {
  const [csrftoken, setCsrftoken] = useState(null);

  const sessionEndpoint = "session";

  const makeEndpoint = (name) => {
    return `${apiEndpoint}${name}/`;
  };

  async function requestSession() {
    const response = await fetch(makeEndpoint(sessionEndpoint), {
      method: "GET",
      credentials: "include",
    });

    const json = await response.json();

    if (response.ok) {
      console.log(json);
      setSessionData(json);
      setCsrftoken(json["csrftoken"]);
      setAdmin(json["admin"]);
      return json;
    } else {
      // setResponseError(json)
      console.error(json);
    }
  }

  async function makeRequest(
    method,
    endpoint,
    qs,
    fields,
    csrftoken,
    setErrors,
  ) {
    const headers = {
      "Content-Type": "application/json",
    };

    if (csrftoken) {
      fields["csrftoken"] = csrftoken;
      headers["X-CSRFToken"] = csrftoken;
    }

    try {
      const response = await fetch(endpoint + qs, {
        method: method,
        body: JSON.stringify(fields),
        credentials: "include",
        headers: headers,
      });

      const json = await response.json();
      if (!response.ok) {
        setResponseError(json);
        setErrors(json);
        return false;
      }

      setResponseError(null);

      return true;
    } catch (error) {
      console.error(error);
      setResponseError({ detail: [error.message] });
      return false;
    }
  }

  const request = {
    async getSession() {
      return requestSession();
    },

    async putUser(endpoint, values, setErrors) {
      const { username } = values;
      const json = await requestSession();
      const csrftoken = json["csrftoken"];
      return makeRequest(
        "PUT",
        endpoint,
        `/${username}/`,
        values,
        csrftoken,
        setErrors,
      );
    },

    async postData(endpoint, values, setErrors) {
      const json = await requestSession();
      const csrftoken = json["csrftoken"];
      return makeRequest("POST", endpoint, ``, values, csrftoken, setErrors);
    },

    async sendMessage(values, setErrors) {
      const json = await requestSession();
      const csrftoken = json["csrftoken"];
      return makeRequest(
        "POST",
        makeEndpoint("message"),
        ``,
        values,
        csrftoken,
        setErrors,
      );
    },

    async delete_profile(network_name) {
      const response = await fetch(makeEndpoint(`social/${network_name}`), {
        body: JSON.stringify({ csrftoken: csrftoken }),
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });

      const json = await response.json();

      if (!response.ok) {
        setResponseError(json);
        return false;
      }

      setSessionData(json);
      setCsrftoken(json["csrftoken"]);

      return true;
    },

    async make_primary(network_name) {
      const response = await fetch(makeEndpoint(`social/${network_name}`), {
        body: JSON.stringify({ csrftoken: csrftoken }),
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });

      const json = await response.json();

      if (!response.ok) {
        setResponseError(json);
        return false;
      }

      setSessionData(json);
      setCsrftoken(json["csrftoken"]);

      return true;
    },

    async logout(callback) {
      const json = await requestSession();
      const csrftoken = json["csrftoken"];

      const response = await fetch(makeEndpoint(sessionEndpoint), {
        body: JSON.stringify({ csrftoken: csrftoken }),
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });

      const delete_json = await response.json();
      if (!response.ok) {
        setResponseError(delete_json);
        return false;
      }

      callback();
    },
  };

  return request;
}
