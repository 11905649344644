import { blue, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = (parent) => {
  return createTheme(parent, {
    palette: {
      primary: {
        main: "#556cd6",
      },
      secondary: {
        main: "#19857b",
      },
      error: {
        main: red.A400,
      },
    },
    root: {
      nav: {
        display: "inline",
      },
    },
    components: {
      MuiLink: {
        variants: [
          {
            props: { variant: "pointer" },
            style: {
              cursor: "pointer",
              color: "inherit",
            },
          },
        ],
      },
      MuiCircularProgress: {
        variants: [
          {
            props: { variant: "centered" },
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          },
        ],
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            flex: "0 0 auto",
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "dashed" },
            style: {
              textTransform: "none",
              border: `2px dashed ${blue[500]}`,
            },
          },
          {
            props: { variant: "dashed", color: "secondary" },
            style: {
              border: `4px dashed ${red[500]}`,
            },
          },
        ],
      },
    },
  });
};

export default theme;
