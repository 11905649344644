import React from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import { AccountMenu } from "../AccountMenu";
import { ModalTitle } from "./ModalTitle";

const ModalHeader = ({
  page,
  transitioning,
  closeDialog,
  profileDialog,
  logout,
  pages,
  setPageName,
}) => {
  if (transitioning) {
    return <></>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 4,
        alignItems: "center",
      }}
    >
      {/* XXX: extract out */}
      <ModalTitle page={page} pages={pages} setpageName={setPageName} />

      {page.showAccountMenu && (
        <Box
          sx={{
            marginLeft: "auto",
          }}
        >
          <AccountMenu logout={() => logout()} profile={profileDialog} />

          {false && page.name !== "compose" && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ModalHeader;
